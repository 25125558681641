
<template>
  <div class="center">
    <b-modal id="bv-login-modal" v-model="show" size="xl">
      <template #modal-title>
        <h4 class="not-margin">
          Please edit the Wine record
        </h4>
      </template>


      <div class="con-form">

        <label for="form-name">Wine Colour</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-wine-color"
            v-model="form.wine_color"
            placeholder="Wine color"
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Bottle Size</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-bottle-size"
            v-model="form.bottle_size"
            placeholder="Bottle Size"
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Year</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-wine-color"
            v-model="form.year"
            placeholder="Year"
            required
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Notes</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-wine-color"
            v-model="form.notes"
            placeholder="Year"
            required
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Name</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-name"
            v-model="$v.form.name.$model"
            placeholder="Name"
            :state="validateState('name')"
            required
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Button Colour</label>
        <b-input-group size="sm" class="mb-2">
            <b-form-select v-model="$v.form.color.$model" :options="colorOptions" size="sm" :state="validateState('color')" class="form-control"></b-form-select>
        </b-input-group>        

        <label for="form-name">Position</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="$v.form.position.$model"
            placeholder="Position"
            :state="validateState('position')"
            type="number"
            required
          ></b-form-input>
        </b-input-group>        
<!--
        <label for="form-name">Price 25ml ( if 0 not available )</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="form.price_25"
            placeholder="25ml Price"
            type="number"
          ></b-form-input>
        </b-input-group>        
-->
        <label for="form-name">Price 75ml ( if 0 not available )</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="form.price_75"
            placeholder="75ml Price"
            type="number"
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Price 125ml ( if 0 not available )</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="form.price_125"
            placeholder="125ml Price"
            type="number"
          ></b-form-input>
        </b-input-group>        

        <label for="form-name">Price 175ml ( if 0 not available )</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="form.price_175"
            placeholder="175ml Price"
            type="number"
          ></b-form-input>
        </b-input-group>   

        <label for="form-name">Price bottle ( if 0 not available )</label>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            id="form-position"
            v-model="form.price_bottle"
            placeholder="Bottle Price"
            type="number"
          ></b-form-input>
        </b-input-group>        



      </div>

      <template #modal-footer>
        <div class="footer-dialog">
          <b-button block @click="handleSubmit">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  import { validationMixin } from 'vuelidate';
  import { required} from "vuelidate/lib/validators";

  import defaults from '@/plugins/defaults';  


  export default {
    mixins: [validationMixin], 
    props: {
      showdialog: Boolean,
      record:Object,
    },   
    data() {
      return {
        colors:defaults.COLORS,
        sizes:defaults.SIZES,
        form: {
          id: -1,
          wine_color:'',
          bottle_size:'',
          year:'',
          notes:'',
          name: '',
          price: 0,
          color: 0,
          size: 1,
          position: '0',
          price_25:0,
          price_75:0,
          price_125:0,
          price_175:0,
          price_bottle:0,

        }
      }
    },   
    validations: {
      form: {
        name: {
          required
        },
        price: {
          required
        },
        size: {
          required
        },
        color: {
          required
        },
        position: {
          required
        },

      }
    },       
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },      
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()

        console.log(valid)

        this.terminalIdState = valid
        return valid
      },      
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },      
      handleSubmit() {
        this.$v.form.$touch();
        console.log(this.$v.form);
        if (this.$v.form.$anyError) {
          return;
        }
        this.$emit('onSaveClick', this.form)
        // Hide the modal manually

      }
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        },
        set() {
        }
      },
      colorOptions() {
          var retArr = [];
          for (let index = 0; index < this.colors.length; index++) {
              retArr.push({value:(index+1),text:this.colors[index].name})              
          }
          return retArr;
      },
      sizeOptions() {
          var retArr = [];
          for (let index = 0; index < this.sizes.length; index++) {
              retArr.push({value:(index+1),text:this.sizes[index].name})              
          }
          return retArr;
      }
    },
    watch: {
        record: function() {
            this.form = this.record;
        }, 
    }     
  }
</script>

<style>
  .modal-header .close {
    display:none;
  }  
</style>